import React, { useState, useEffect, useRef } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { HashLink as Linkh } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './navbar.css';

const SubMenu = () => (
  <>
    <p><Linkh to="#crown">Dental Crown</Linkh></p>
    <p><Linkh to="#fillings">Filling Cavities</Linkh></p>
    <p><Linkh to="#implants">Dental Implants</Linkh></p>
    <p><Linkh to="#cleaning">Teeth Cleaning</Linkh></p>
    <p><Linkh to="#dentures">Dentures</Linkh></p>
    <p><Linkh to="#braces">Braces and Aligners</Linkh></p>
    <p><Linkh to="#root-canal">Root Canal</Linkh></p>
    <p><Linkh to="#tooth-removal">Tooth Removal</Linkh></p>
  </>
);

const MenuItem = ({ label, subMenu }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const subMenuRef = useRef(null);

  const path = label === 'Home' ? '': `/${label.toLowerCase()}`;

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleOutsideClick = (event) => {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
      setIsSubMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isSubMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isSubMenuOpen]);

  return (
    <div className="navbar-menu-item" onMouseEnter={handleSubMenuToggle} onMouseLeave={handleSubMenuToggle}>
      <Link to={path} onClick={() => setIsSubMenuOpen(false)}>
        {label}
      </Link>
      {subMenu && isSubMenuOpen && (
        <div className="navbar-submenu" ref={subMenuRef}>
          {subMenu}
        </div>
      )}
    </div>
  );

 
};



const Menu = () => (
  <>
    <MenuItem label="Home" />
    <MenuItem label="Services" subMenu={<SubMenu />} />
    <MenuItem label="FAQ" />
    <MenuItem label="Blog" />
    
  </>
);

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setToggleMenu(false);
      }
    };

    if (toggleMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [toggleMenu]);

  return (
    
    <div className="kedan__navbar">
      {/* {console.log(Menu)} */}
      <div className="kedan__navbar-links">
        <div className="kedan__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="kedan__navbar-links_containers">
          <Menu />
        </div>
      </div>
      <div className="kedan__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="kedan__navbar-menu_container scale-up-center" ref={menuRef}>
            <div className="kedan__navbar-menu_container-links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;






// import React, { useState, useEffect, useRef } from 'react';
// import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import { Link } from 'react-router-dom';
// import logo from '../../assets/logo.png';
// import './navbar.css';

// const SubMenu = () => (
//   <>
//     <p><Link to="procedure1">Procedure1</Link></p>
//     <p><Link to="procedure2">Procedure2</Link></p>
//     <p><Link to="procedure3">Procedure3</Link></p>
//     <p><Link to="procedure4">Procedure4</Link></p>
//     <p><Link to="procedure5">Procedure5</Link></p>
//     <p><Link to="procedure6">Procedure6</Link></p>
//     <p><Link to="procedure7">Procedure7</Link></p>
//   </>
// );

// const Menu = () => (
//   <>
//     <p><a href="#home">Home</a></p>
//     <p><a href="#home">Services</a></p>
//     <p><a href="#home">Book_Appointment</a></p>
//     <p><a href="#home">FAQ</a></p>
//   </>
// )


// function Navbar() {
//   const [toggleMenu, setToggleMenu] = useState(false);
//   const menuRef = useRef(null);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         // Click occurred outside the menu, close it
//         setToggleMenu(false);
//       }
//     };

//     const handleEscapeKey = (event) => {
//       if (event.key === 'Escape') {
//         // Close the menu when the Escape key is pressed
//         setToggleMenu(false);
//       }
//     };

//     if (toggleMenu) {
//       document.addEventListener('mousedown', handleOutsideClick);
//       document.addEventListener('keydown', handleEscapeKey);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick);
//       document.removeEventListener('keydown', handleEscapeKey);
//     };
//   }, [toggleMenu]);

//   return (
//     <div className="kedan__navbar">
//       <div className="kedan__navbar-links">
//         <div className="kedan__navbar-links_logo">
//           <img src={logo} alt="logo" />
//         </div>
//         <div className="kedan__navbar-links_containers">
//           <Menu />
//         </div>
//       </div>
//       <div className="kedan__navbar-menu">
//         {toggleMenu ? (
//           <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
//         ) : (
//           <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
//         )}
//         {toggleMenu && (
//           <div className="kedan__navbar-menu_container scale-up-center" ref={menuRef}>
//             <div className="kedan__navbar-menu_container-links">
//               <Menu />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Navbar;










// import React, { useState, useEffect, useRef  } from 'react';
// import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import logo from '../../assets/logo.png';
// import './navbar.css';

// const Menu = () => (
//   <>
//     <p><a href="#home">Home</a></p>
//     <p><a href="#home">Services</a></p>
//     <p><a href="#home">Book_Appointment</a></p>
//     <p><a href="#home">FAQ</a></p>
//   </>
// )

// function Navbar() {
//   const [toggleMenu, setToggleMenu] = useState(false);

//   const menuRef = useRef(null);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         // Click occurred outside the menu, close it
//         setToggleMenu(false);
//       }
//     };

//     if (toggleMenu) {
//       // Add the event listener when the menu is open
//       document.addEventListener('mousedown', handleOutsideClick);
//     }

//     return () => {
//       // Remove the event listener when the component unmounts or the menu is closed
//       document.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, [toggleMenu]);



//   return (
//     <div className="kedan__navbar">
//       <div className="kedan__navbar-links">
//         <div className="kedan__navbar-links_logo">
//           <img src={logo} alt="logo" />
//         </div>
//         <div className="kedan__navbar-links_containers">
//           <Menu />
//         </div>
//       </div>
//       <div className="kedan__navbar-menu">
//         {toggleMenu ? (
//           <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
//         ) : (
//           <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
//         )}
//         {toggleMenu && (
//           <div className="kedan__navbar-menu_container scale-up-center" ref={menuRef}>
//             <div className="kedan__navbar-menu_container-links">
//               <Menu />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Navbar;

//   return (
//     <div className="kedan__navbar">
//       <div className="kedan__navbar-links">
//         <div className="kedan__navbar-links_logo">
//           <img src={logo} alt="logo" />
//         </div>
//         <div className="kedan__navbar-links_containers">
//           <Menu />
//         </div>
//       </div>
//       <div className="kedan__navbar-menu">
//         {toggleMenu
//           ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
//           : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
//         }
//         {toggleMenu && (
//           <div className="kedan__navbar-menu_container scale-up-center">
//             <div className="kedan__navbar-menu_container-links">
//               <Menu />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   )
//   }
// export default Navbar
