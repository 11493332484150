import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CTA, Feature, Navbar, MainSection, USP, Footer, SideBar, FaqPage, BlogPage } from './components';
import slides  from './components/mainSection/slides.json';
import testimonials from './components/usp/testimonials.json';
import './App.css'


const MainContent = () => {
  return (
    <>
      <MainSection data={slides} />
      <CTA showAppointment showAsk />
      <USP data={testimonials}/>
      <Feature />
      <hr className='divider' />
      <Footer />
    </>
  );
};


const App = () => {
  // const location = useLocation();
  return (
    <Router>
      <div className="App">
        <div className="navbar0">
          <Navbar />
        </div>
        <div className='main-section'>
          <div className="side-bar">
            <SideBar />
          </div>
          <div className="main-content">
            <Routes>
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/services" element={<MainContent />}/>
              <Route path="/" element={<MainContent />}/>
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
