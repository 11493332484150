import * as React from 'react';
import './cta.css';
// import { format, addDays, isSaturday } from 'date-fns';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getDay } from 'date-fns';
// import Typography from '@mui/material/Typography';


const CustomTextField = ({id, name, label, type, isRequired, maxLength, ...props }) => {
  return (
    <TextField
      autoFocus
      required={isRequired}
      margin="dense"
      id={id}
      name={name}
      label={label}
      type={type}
      fullWidth
      inputProps={{ maxLength: maxLength }}
      variant="standard"
      {...props} // Any additional props you want to pass
    />
  );
};


const shouldDisableDate = (date) => {
  // Disable weekends (Saturday and Sunday)
  if (getDay(date) === 6) {
    return true;
  }

  const today = new Date();
    today.setDate(today.getDate()); // Move to the day after today
    return date < today;

  // You can add additional logic to disable specific days
    // return false;
};

function CTA({showAppointment, showAsk}) {
  const [openAppointment, setOpenAppointment] = React.useState(false);
  const [openAsk, setOpenAsk] = React.useState(false);

  const handleClickOpenAppointment = () => {
    setOpenAppointment(true);
  }
  const handleClickOpenAsk = () => {
    setOpenAsk(true);
  }
  const handleCloseAppointment = () => {
    setOpenAppointment(false);
  }
  const handleCloseAsk = () => {
    setOpenAsk(false);
  }
  ///////////////////////////
    const handleSubmitAppointment = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const appointmentformJson = Object.fromEntries(formData.entries());

    console.log(appointmentformJson);

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointmentformJson),
      });

      if (response.ok) {
        console.log('Email sent successfully!');
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }

    handleCloseAppointment();
  };

  const handleSubmitAsk = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('/send-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formJson),
      });

      if (response.ok) {
        console.log('Question sent successfully!');
      } else {
        console.error('Failed to send question');
      }
    } catch (error) {
      console.error('Error sending question:', error);
    }

    handleCloseAsk();
  };
  ////////////////////////////////

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const isDateSelected = selectedDate !== null;
  
  return (
    <>
      <div className="cta">
        { showAppointment && (
          <div className="appointment">
          <Button className={"cta-button"} variant="contained" onClick={handleClickOpenAppointment} style={{fontWeight: '900'}}>
            Book an Appointment
          </Button>
          <Dialog open={openAppointment}
            onClose={handleCloseAppointment}
            PaperProps={{
              component: 'form',
              onSubmit: handleSubmitAppointment,
              // (event) => {
              //   event.preventDefault();
              //   const formData = new FormData(event.currentTarget);
              //   const appointmentformJson = Object.fromEntries(formData.entries());
              //   handleCloseAppointment();
              // },
            }}
          >
            <DialogTitle>Book Appointment</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseAppointment}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContentText style={{marginLeft: '25px'}}>
              We are open Monday - Friday (9:00AM - 4:00PM) and Sunday (9:30AM - 3:30PM)
            </DialogContentText>
            <DialogContent>
              <CustomTextField id="email" name="email" label="Email Address" type="email" isRequired/>
              <CustomTextField id="name" name="name" label="Name" type="text" isRequired maxLength={30}/>
              <CustomTextField id="phoneNumber" name="phoneNumber" label="Phone Number" type="tel" isRequired/>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className='date-picker'
                  required={!isDateSelected}
                  margin="dense"
                  id="date"                
                  name="date"
                  label="Pick Date"
                  inputFormat="dd/MM/yyyy"
                  value={new Date()} // You may want to set the initial value here
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
                  shouldDisableDate={shouldDisableDate}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              <Button type="submit">Submit</Button>
            </DialogActions>

          </Dialog>
        </div>)}
        {showAsk && (
        <div className="askTheDentist">
          <Button variant="contained" className="cta-button" onClick={handleClickOpenAsk} style={{fontWeight: '900'}}>
              Uliza Daktari
            </Button>
            <Dialog open={openAsk}
              onClose={handleCloseAsk}
              PaperProps={{
                component: 'form',
                onSubmit: handleSubmitAsk,
                // (event) => {
                //   event.preventDefault();
                //   const formData = new FormData(event.currentTarget);
                //   const formJson = Object.fromEntries(formData.entries());
                //   handleCloseAsk();
                // },
              }}
            >
              <DialogTitle>Uliza Daktari (Ask The Dentist)</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseAsk}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon />
              </IconButton>
              <DialogContent>
                <CustomTextField id="question" name="question" label="Swali" type="text" isRequired maxLength={1000}/>
                <CustomTextField id="name" name="name" label="Name" type="text" isRequired maxLength={30}/>
                <CustomTextField id="phoneNumber" name="phoneNumber" label="Phone Number" type="tel" isRequired/>
                <CustomTextField id="email" name="email" label="Email Address" type="email"/>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleClose}>Cancel</Button> */}
                <Button type="submit">Send</Button>
              </DialogActions>

            </Dialog>
          </div>)}
      </div>
    </>
  )
}

export default CTA
