import React from 'react';
import './faqpage.css';

function FaqPage() {
  return (
    <div className="faq-page">
        <h2>FAQ</h2>
        <p>FAQ come here</p>
    </div>
  )
}

export default FaqPage
