import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        <div className="f-links"><h4>Usefull Links:</h4></div>
        <div className="f-links"><Link to='/'>Home</Link></div>
        <div className="f-links"><Link to='/faq'>FAQ</Link></div>
        <div className="f-links"><Link to='/blog'>Blog</Link></div>
      </div>
      <p><i>&#169; KedanDentals 2024. Built with passion by Marbet</i></p>
    </div>
  )
}

export default Footer
