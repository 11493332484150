import React from 'react';
import './feature.css';

const Price = (props) => {
  return (
    <li><b>Approximate Cost: </b><i>KES: {props.min} to {props.max}.</i> <b>NOTE: </b>The price will vary from person to person according to the extend of the illness/complication.</li>
  );
};

function Feature() {
  return (
    <div className="feature-services">
      <div className="service-container" id="crown">
        <img src="./assets/p1.jpg" alt="Dental Crowns" className="service-image"/>
        <div className="s-text-container">
          <h3 className="service-title">Dental Crown</h3>
          <p className="service-text">
            <li><b>Preparation: </b>The damaged or decayed tooth is carefully reshaped to allow space for the crown, ensuring a proper fit and alignment.</li>
            <li><b>Impression: </b>An impression or mold of the newly prepared tooth is taken, capturing its unique shape and characteristics to guide the fabrication of the custom crown.</li>
            <li><b>Crown Fabrication: </b>Skilled technicians in a dental laboratory use the impression to craft a custom crown, selecting materials that match the natural teeth in color and function.</li>
            <li><b>Crown Placement: </b>The final crown is securely cemented onto the prepared tooth, completing the restoration process and restoring both the appearance and functionality of the tooth.</li>
            <Price min={10000} max={50000} />
          </p>
        </div>
      </div>
      <div className="service-container" id="fillings">
        <div className="s-text-container">
          <h3 className="service-title">Filling Cavities</h3>
          <p className="service-text">
            <li><b>Preparation: </b>The dentist removes decayed or damaged tooth material and cleans the area to create a suitable surface for the dental filling.</li>
            <li><b>Filling Application: </b>A chosen filling material (such as composite resin, amalgam, or ceramic) is placed into the prepared tooth cavity, restoring its structure.</li>
            <li><b>Shaping and Bonding: </b>The filling material is shaped to match the natural tooth contours and then bonded or cured using a special light to ensure a strong and secure attachment.</li>
            <li><b>Final Adjustment: </b>The dentist makes final adjustments to the filling's shape and ensures a proper bite alignment, providing a functional and aesthetically pleasing restoration.</li>
            <Price min={10000} max={40000} />
          </p>
        </div>
        <img src="./assets/p2.jpg" alt="Fillings" className="service-image"/>
      </div>
      
      <div className="service-container" id="implants">
        <img src="./assets/p3.jpg" alt="Dental Implants" className="service-image"/>
        <div>
          <h3 className="service-title">Dental Implants</h3>
          <p className="service-text">
            <li><b>Evaluation: </b>Before the surgical procedure, a thorough examination and evaluation of the patient's oral health, including X-rays and impressions, are conducted to determine the suitability for a dental implant and to plan the implant placement.</li>
            <li><b>Surgical Placement: </b>A dental implant, typically a titanium post, is surgically inserted into the jawbone at the site of the missing tooth, acting as a replacement for the natural tooth root.</li>
            <li><b>Healing Period: </b>Over the next few weeks to months, the jawbone undergoes osseointegration, where it fuses with the implant, providing a stable and secure foundation for the artificial tooth.</li>
            <li><b>Abutment Attachment: </b>After successful osseointegration, an abutment is attached to the implant, creating a connector for the eventual placement of the artificial tooth.</li>
            <li><b>Artificial Tooth Placement: </b>A custom-designed artificial tooth, or crown, is then securely attached to the abutment, completing the dental implant process and restoring both function and aesthetics.</li>
            <Price min={18000} max={55000} />
          </p>
        </div>
      </div>
      <div className="service-container" id="cleaning">
        <div className="s-text-container">
          <h3 className="service-title">Teeth Cleaning</h3>
          <p className="service-text">
            <li><b>Evaluation and Examination: </b>The dental hygienist assesses the overall oral health, examining teeth and gums, and may take X-rays to identify any issues before the cleaning.</li>
            <li><b>Plaque and Tartar Removal: </b>Using specialized tools, the hygienist removes plaque (a soft, sticky film of bacteria) and hardened tartar (calculus) from the tooth surfaces, especially in hard-to-reach areas.</li>
            <li><b>Scaling and Polishing: </b>The hygienist scales the teeth to remove stubborn tartar, and then polishes the teeth to smooth surfaces and remove stains, leaving them clean and shiny.</li>
            <li><b>Education and Recommendations: </b>The dental hygienist provides oral hygiene tips, discusses proper brushing and flossing techniques, and may offer recommendations for maintaining optimal oral health between cleanings.</li>
            <Price min={4000} max={10000} />
          </p>
        </div>
        <img src="./assets/p4.jpg" alt="Teeth Cleaning" className="service-image"/>
      </div> 
      <div className="service-container" id="dentures">
        <img src="./assets/p5.jpg" alt="Dentures" className="service-image"/>
        <div className="s-text-container">
          <h3 className="service-title">Dentures</h3>
          <p className="service-text">
            <li><b>Dental Impression: </b>The dentist takes impressions or molds of the patient's gums and any remaining teeth to create a custom-fit for the dentures.</li>
            <li><b>Model Creation: </b>Using the impressions, a dental laboratory constructs a precise model of the patient's oral structure to ensure the dentures will fit comfortably and function properly.</li>
            <li><b>Denture Fabrication: </b>The dentures, typically made of acrylic or a combination of materials, are crafted based on the model. They include prosthetic teeth set in a gum-colored base that mimics the appearance of natural gums.</li>
            <li><b>Fitting and Adjustment: </b>The dentist fits the initial set of dentures to the patient's mouth, making adjustments for comfort and proper alignment. This may involve multiple appointments to achieve the optimal fit.</li>
            <li><b>Education and Maintenance: </b>The dentist provides instructions on how to insert, remove, and clean the dentures. Patients are educated on proper oral hygiene practices and advised on periodic check-ups to assess the dentures' fit and make any necessary adjustments.</li>
            <Price min={10000} max={50000} />
          </p>
        </div>
      </div>
      <div className="service-container" id="braces">
        <div className="s-text-container">
          <h3 className="service-title">Braces and Aligners</h3>
          <p className="service-text">
            <li><b>Orthodontic Assessment: </b>The orthodontist conducts a comprehensive examination, which may include X-rays and 3D scans, to assess the current alignment of teeth and jaw structure and determine the need for braces or aligners.</li>
            <li><b>Treatment Planning: </b>Based on the assessment, the orthodontist develops a customized treatment plan. For braces, this involves selecting the type of braces (traditional metal, ceramic, or lingual), and for aligners, a series of clear, removable trays are designed.</li>
            <li><b>Braces Placement or Aligner Fitting: </b>Braces are attached to the teeth with adhesive and connected by wires, while aligners are custom-fitted to the patient's teeth. For aligners, a series of trays is provided to be worn sequentially, gradually moving the teeth into the desired position.</li>
            <li><b>Periodic Adjustments: </b>For braces, periodic appointments are scheduled for adjustments, where the orthodontist tightens wires or changes bands to continue guiding teeth into the correct alignment. Aligner users progress to the next set of trays at regular intervals, typically every few weeks.</li>
            <li><b>Monitoring and Post-Treatment: </b>Regular check-ups with the orthodontist ensure that the treatment progresses as planned. Once the desired alignment is achieved, braces are removed, and retainers may be provided to maintain the new position of the teeth. Aligner users may also use retainers as recommended.</li>
            <Price min={40000} max={120000} />
          </p>
        </div>
        <img src="./assets/p6.jpg" alt="Braces" className="service-image"/>
      </div>
      <div className="service-container" id="root-canal">
        <img src="./assets/p7.jpg" alt="Root Canal" className="service-image"/>
        <div className="s-text-container">
          <h3 className="service-title">Root Canal Treatment</h3>
          <p className="service-text">
            <li><b>Diagnostic Examination: </b>The dentist evaluates the tooth, often using X-rays, to diagnose the extent of damage or infection in the pulp (inner soft tissue) and determines the need for a root canal.</li>
            <li><b>Pulp Removal: </b>During the procedure, the dentist numbs the affected tooth and removes the infected or damaged pulp. The root canals are then cleaned, shaped, and disinfected to remove any remaining bacteria.</li>
            <li><b>Filling and Sealing: </b>After cleaning, the dentist fills the emptied root canals with a biocompatible material, usually gutta-percha, and seals the tooth to prevent further infection. In some cases, a temporary filling is placed until a permanent restoration, like a crown, can be added.</li>
            <li><b>Final Restoration: </b>To restore the tooth's strength and functionality, a final restoration, often a dental crown, is placed over the treated tooth. This protects the tooth from further damage and allows it to function normally.</li>
            <Price min={10000} max={50000} />
          </p>
        </div>
      </div>
      <div className="service-container" id="tooth-removal">
        <div className="s-text-container">
          <h3 className="service-title">Tooth Removal</h3>
          <p className="service-text">
            <li><b>Evaluation and X-rays: </b>The dentist evaluates the tooth's condition through a clinical examination and X-rays to assess the extent of damage, decay, or other issues, determining whether extraction is necessary.</li>
            <li><b>Anesthesia: </b>Before the extraction, the dentist administers a local anesthetic to numb the tooth and the surrounding area, ensuring the patient's comfort during the procedure. In some cases, sedation may be used for more complex extractions or anxious patients.</li>
            <li>Extraction Procedure: <b></b>The dentist uses specialized instruments to loosen the tooth within its socket and then carefully extracts it. The procedure may involve rocking the tooth back and forth to widen the socket before removal. In some cases, a surgical extraction may be needed for impacted or broken teeth.</li>
            <li><b>Post-Extraction Care: </b>After extraction, the dentist provides instructions for post-operative care, which may include guidance on managing any discomfort, swelling, or bleeding. In some cases, the dentist may prescribe antibiotics or pain medications. Patients are advised on proper oral hygiene during the healing process.</li>
            <Price min={1000} max={5000} />
          </p>
        </div>
        <img src="./assets/p8.jpeg" alt="Tooth Removal" className="service-image"/>
      </div>
    </div>
  )
}

export default Feature