import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/swiper-bundle.css';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import './mainsection.css';
import "swiper/css/autoplay";

// SwiperCore.use([Autoplay]);

function MainSection({ data }) {
  const [swiper, setSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const handleSlideChange = () => {
    // console.log('Slide changed!');
    if (swiper) {
      setActiveSlide(swiper.activeIndex);
    }
  };

  const handleMouseDown = () => {
    // Disable autoplay when the user clicks and holds
    setAutoplayEnabled(false);
  };

  const handleMouseUp = () => {
    // Enable autoplay when the user releases the click
    setAutoplayEnabled(true);
  };

  const handleMouseLeave = () => {
    // Enable autoplay when the mouse leaves the Swiper component
    setAutoplayEnabled(true);
  };

  return (
    <div className="carousel">
      <Swiper
        modules={[Autoplay]}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => setSwiper(swiper)}
        spaceBetween={10} // You can adjust the space between slides
        slidesPerView={1} // Number of slides to show at once
        centeredSlides={true} // Center the active slide
        loop={true} // Enable infinite loop
        autoplay={{ delay: 5000, disableOnInteraction: true, enabled: autoplayEnabled }} // Autoplay configuration
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {data.slides.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="slide">
              {/* Your slide content */}
              <BsArrowLeftCircleFill
                className="arrow left-arrow"
                onClick={() => goToSlide(activeSlide === 0 ? data.slides.length - 1 : activeSlide - 1)}
                style={{ fontSize: '30px' }}
              />
              <img
                src={item.src}
                alt={item.alt}
                className="slide-img"
              />
              <div className="description">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
              <BsArrowRightCircleFill
                className="arrow right-arrow"
                onClick={() => goToSlide(activeSlide === data.slides.length - 1 ? 0 : activeSlide + 1)}
                style={{ fontSize: '30px' }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="indicators" >
        {data.slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={activeSlide === index ? 'indicator' : 'indicator indicator-inactive'}
            
          ></button>
        ))}
      </div>
    </div>
  );
}

export default MainSection;






