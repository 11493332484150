import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import 'swiper/css/bundle';
import 'swiper/css';
import "swiper/css/autoplay";
import './usp.css';

function USP({ data }) {
  const [swiper, setSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const handleSlideChange = () => {
    // console.log('Slide changed!');
    if (swiper) {
      setActiveSlide(swiper.activeIndex);
    }
  };

  const handleMouseDown = () => {
    // Disable autoplay when the user clicks and holds
    setAutoplayEnabled(false);
  };

  const handleMouseUp = () => {
    // Enable autoplay when the user releases the click
    setAutoplayEnabled(true);
  };

  const handleMouseLeave = () => {
    // Enable autoplay when the mouse leaves the Swiper component
    setAutoplayEnabled(true);
  };


  return (
    <div className="ups">
      <h2 className="">Why choose Kedan?</h2>
        <div className="why-kedan">
          <div className="reasons">
            <h3><u>State of the Art Equipment</u></h3>
            <p>Our facility is equiped with up to date digital dental equipement that guarantee the Best possible quality treatment comfortably.</p>
          </div>
          <div className="reasons">
            <h3><u>Professional Doctor</u></h3>
            <p>Professionaly trained and Highly skilled Dentist will attend to you, Providing the best Diagnosis and remedy/improvement for your dental & oral health.</p>
          </div>
          <div className="reasons">
            <h3><u>Affordable</u></h3>
            <p>We are fully aware of the economic situation and are commited to offering you the best services at the best prices possible.
              We also make arrangements for payments in phases/installment.</p>
          </div>
        </div>
      <div className="space"></div>
      <h2 className="">Testimonials</h2>
        <div className='testimonials'>
          <BsArrowLeftCircleFill
                    className="arrow-testimonial left-arrow"
                    onClick={() => goToSlide(activeSlide === 0 ? data.testimonials.length - 1 : activeSlide - 1)}
                    style={{ fontSize: '50px' }}
                  />
          <Swiper
          modules={[Autoplay]}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => setSwiper(swiper)}
          spaceBetween={20} // You can adjust the space between slides
          // slidesPerView={3} // Number of slides to show at once
          centeredSlides={true} // Center the active slide
          loop={true} // Enable infinite loop
          autoplay={{ delay: 4000, disableOnInteraction: true, enabled: false }} // Autoplay configuration
          breakpoints={{
            // When window width is <= 576px
            576: {
              slidesPerView: 1,
            },
            // When window width is <= 768px
            768: {
              slidesPerView: 3,
            },
            // When window width is <= 992px
            992: {
              slidesPerView: 3,
            },
            // ... add more breakpoints as needed
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          >
          {data.testimonials.map((item, index) => (
            <div className="swiper-container" key={index}>
              <SwiperSlide key={index}>
                <div className="slide-testimonial">
                  <div className="description-testimonial">
                    <h4>{item.name}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              </SwiperSlide>
              
            </div>
            ))}
          </Swiper>
          <BsArrowRightCircleFill
                    className="arrow-testimonial right-arrow"
                    onClick={() => goToSlide(activeSlide === data.testimonials.length - 1 ? 0 : activeSlide + 1)}
                    style={{ fontSize: '50px' }}
                  />
        </div>
    </div>
  )
}

export default USP
