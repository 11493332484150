import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CTA } from '../index';
import './sidebar.css';
import logo from '../../assets/logo.png';

function SideBar() {
  return (
    <div className="side-bar1">
      <div className="logo-container">
        <img src={logo} alt="logo" className="sidebar-logo"/>
      </div>
      <div>
        <CTA showAppointment style={{margin: 0}}/>
      </div>
      {/* <div>Google Map 7QP3+24 Kakamega</div> */}
      <div className="location-text">
        <h4>We are locate at the</h4>
        <p>NCBA bank building 2nd floor in Kakamega, along Canon Awori street.
          Western Province, Kenya.</p>
        <a href="https://www.google.com/maps/place/Kedan+Dental+Centre/@0.2851199,34.7502045,17z/data=!3m1!4b1!4m6!3m5!1s0x17803c381041c1a7:0xdbce2ef9884c2932!8m2!3d0.2851145!4d34.7527794!16s%2Fg%2F1td5ch8q?entry=ttu"
           rel="noreferrer" target="_blank">Get Direction</a>
      </div>
      <div className="sidebar-contact">
        <h4><u>Contact Us</u></h4>
        <div className="sidebar-phone">
          <a href="+254746039111" className="phone-link"><FontAwesomeIcon icon={faPhone} className="icon-p"/>
          <div className="spaced"></div>
          <span>0746039111</span></a>
        </div>
        <div className="sidebar-mail">
          <a href="#" className="mail-link"><FontAwesomeIcon icon={faEnvelope} className="icon-m"/>
          <div className="spaced"></div>
          <span>kedandental@gmail.com</span></a>
        </div>
      </div>
      {/* <div>Offers</div> */}
    </div>
  )
}

export default SideBar
